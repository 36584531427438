<template>
    <v-container fluid>
        <v-layout row>
            <div class="text-xs-center title font-weight-thin">
                <v-dialog v-model="myVisibleDialog" persistent width="unset">
                    <v-card
                        color="black"
                        class="headline blue lighten-2 white--text text-md-center"
                        primary-title>
                        <v-card-title>
                          <v-spacer></v-spacer>
                          <span>Upload/Download Plan</span>
                          <v-spacer></v-spacer>
                        <v-btn
                            id="btn-closeforward"
                            depressed
                            small
                            class="transparent right mb-0 btn-fwd"
                            @click="myVisibleDialog = false"><v-icon>{{icons.mdiClose}}</v-icon></v-btn>
                        </v-card-title>
                        <FileInput
                            @onUploaded="onUploadFile"
                            :patient_Info="patient_Info"
                        ></FileInput>
                        <v-divider></v-divider>
                        <ListFiles
                            :patient_Info="patient_Info"
                            :file_lists="file_lists"
                        ></ListFiles>
                    </v-card>
                </v-dialog>
            </div>

            <vsnackbar :snackbar="snackbar" />
        </v-layout>
    </v-container>
</template>

<script>
import {
  mdiClose
} from '@mdi/js'
import { getFileList, handleExpireSession } from '@/api/api'
import FileInput from '@/components/UploadPlan/File_Input'
import ListFiles from '@/components/UploadPlan/list_Files'
import vsnackbar from '@/components/Widgets/v-snackbar'
export default {
  props: ['uploadDialogVisible', 'setVisibleUploadDialog', 'patient_Info'],
  components: { FileInput, ListFiles, vsnackbar },
  data () {
    return {
      icons: { mdiClose },
      FileInputVisible: false,
      ListFilesVisible: true,
      myVisibleDialog: false,
      selectedFile: '',
      file_lists: [],
      snackbar: {
        show: false,
        message: '',
        colorValue: 'success',
        timeout: 5000
      }

    }
  },
  watch: {
    uploadDialogVisible: function (val) {
      // console.log("uploadDialogVisible..... : " + val)
      if (val) {
        this.getFileListDialog(this.patient_Info)
      }
      this.myVisibleDialog = val
    },
    myVisibleDialog (val) {
      // Notify Parent that I'm close
      if (val === false) {
        this.setVisibleUploadDialog(false)
        this.selectedFile = ''
      }
    }

  },
  mounted () {
  },
  methods: {
    getFileListDialog (val) {
      var _this = this
      // console.log(val)
      getFileList(val.Ctdata_SerieUID).then((res) => {
        // console.log(res.data)
        _this.file_lists = res.data.files
      }).catch(function (err) {
        handleExpireSession(err, _this)
      })
    },
    onUploadFile (val) {
      var _this = this
      if (val.isSuccess) {
        getFileList(_this.patient_Info.Ctdata_SerieUID).then((res) => {
          // console.log(res.data)
          _this.file_lists = res.data.files
          this.snackbar.show = true
          this.snackbar.message = 'Upload DTP Successful !!'
          this.snackbar.colorValue = 'success'
        }).catch(function (err) {
          handleExpireSession(err, _this)
        })
      } else {
        this.snackbar.show = true
        this.snackbar.message = 'Upload DTP failed !! File is already in system.'
        this.snackbar.colorValue = 'red'
      }
    }
  }
}
</script>

<style scoped>
.btn-fwd {
  min-width: 48px;
}
</style>
