<template>
    <v-flex>
        <v-card class="pa-2 pt-6">
            <v-form lazy-validation ref="form">
                <v-text-field
                    id="input-doctorname"
                    label="Doctor Name"
                    :rules="[ (v) => !!v || 'Doctor name is required']"
                    v-model="sub_doctor_info.subdoctor_name"
                    :readonly="selectedDoctor != ''"
                    :prepend-icon="icons.mdiFace"
                    outlined
                ></v-text-field>
                <v-text-field
                    id="input-doctoremail"
                    label="E-mail"
                    :rules="[ (v) => !!v || 'E-mail is required',
             (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid']"
                    v-model="sub_doctor_info.subdoctor_email"
                    :readonly="selectedDoctor != ''"
                    :prepend-icon="icons.mdiEmail"
                    outlined
                ></v-text-field>
                <v-text-field
                    id="input-doctorinstitution"
                    label="Hospital/Clinic"
                    :rules="[ (v) => !!v || 'Hospital/Clinic is required']"
                    v-model="sub_doctor_info.subdoctor_institution"
                    :readonly="selectedDoctor != ''"
                    :prepend-icon="icons.mdiHospitalBuilding"
                    outlined
                ></v-text-field>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn id="btn-sent" @click="onAdd_Sent" v-if="selectedDoctor == ''">SHARE</v-btn>
                    <v-btn id="btn-sent" @click="onSent" v-if="selectedDoctor != ''">SHARE</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-flex>
</template>

<script>
import {
  mdiFace,
  mdiEmail,
  mdiHospitalBuilding
} from '@mdi/js'
import { postRegisterSubDoctor, postForwardPatient, handleExpireSession } from '@/api/api'
export default {
  props: ['patient_List', 'selectedDoctor'],
  data () {
    return {
      icons: { mdiFace, mdiEmail, mdiHospitalBuilding },
      sub_doctor_info: {
        subdoctor_name: '',
        subdoctor_email: '',
        subdoctor_institution: ''
      }
    }
  },
  mounted () {
    this.sub_doctor_info.subdoctor_name = this.selectedDoctor.name
    this.sub_doctor_info.subdoctor_email = this.selectedDoctor.email
    this.sub_doctor_info.subdoctor_institution = this.selectedDoctor.institution
  },
  computed: {
    form () {
      return {
        sub_doctor_info: {
          subdoctor_name: this.sub_doctor_info.subdoctor_name,
          subdoctor_email: this.sub_doctor_info.subdoctor_email,
          subdoctor_institution: this.sub_doctor_info.subdoctor_institution
        }
      }
    }
  },
  methods: {
    onAdd_Sent () {
      var _this = this
      if (this.$refs.form.validate()) {
        var userInfo = JSON.parse(localStorage['RadiiView-DentiiCloud']).user
        if (userInfo.User_Email != this.sub_doctor_info.subdoctor_email) {
          const para = {
            token: JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication,
            subdoctor_name: this.sub_doctor_info.subdoctor_name,
            subdoctor_email: this.sub_doctor_info.subdoctor_email,
            subdoctor_institution: this.sub_doctor_info.subdoctor_institution
          }
          postRegisterSubDoctor(para).then((res) => {
            if (res.status == 200) {
              const registerObject = {
                isSuccess: true,
                Info: para
              }
              this.$emit('onRegister', registerObject)

              var param = []
              this.patient_List.forEach(element => {
                param.push({
                  series_uid: element.Ctdata_SerieUID,
                  patient_id_clinic: element.patient_ID_Clinic || element.Patient_ID_Clinic,
                  subdoctor_email: this.sub_doctor_info.subdoctor_email
                })
              })
              this.ForwardPatient(param)
            } else {
              this.$emit('onRegister', { isSuccess: false })
            }
          }).catch(function (err) {
            handleExpireSession(err, _this)
          })
        } else {
          this.$emit('onRegister', { isSuccess: false })
        }
      }
    },
    ForwardPatient (param) {
      var _this = this
      // console.log(param)
      postForwardPatient(param).then((res) => {
        if (res.status == 200) {
          param.isSuccess = true
          param.email = param.subdoctor_email
          param.name = this.sub_doctor_info.subdoctor_name
          param.institution = this.sub_doctor_info.subdoctor_institution
          this.$emit('onForward', param)
        } else {
          param.isSuccess = false
          this.$emit('onForward', param)
        }
      }).catch(function (err) {
        handleExpireSession(err, _this)
      })
    },
    onSent () {
      var param = []
      this.patient_List.forEach(element => {
        param.push({
          series_uid: element.Ctdata_SerieUID,
          patient_id_clinic: element.patient_ID_Clinic || element.Patient_ID_Clinic,
          subdoctor_email: this.sub_doctor_info.subdoctor_email
        })
      })
      this.ForwardPatient(param)
    }

  }
}
</script>

<style>
</style>
