import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import jwt_decode from 'jwt-decode'
import vueCookies from 'vue-cookies'
import { isMobile } from 'mobile-device-detect'

import { getBasebackendApi, requestLoginToken } from '@/api/api'

import VueNativeSock from 'vue-native-websocket'
import '@/registerServiceWorker'

Vue.config.productionTip = false
Vue.use(vueCookies)
// GLobal BEFORE hooks:
router.beforeEach((to, from, next) => {
  // console.log('Global -- beforeEach - fired')
  getBasebackendApi().then((response) => {
    // console.log("Call function finish."); // gets here when the promise is resolved
    Vue.use(VueNativeSock, store.state.baseURL.socketURL, { store: store })
    // Vue.use(VueNativeSock, 'ws://10.225.12.171:25003/socket.io', { store: store })
    next()
  }, () => {
    // console.error(error) // gets here when the promise is rejected
  })
})

// Global beforeResolve
router.beforeResolve((to, from, next) => {
  // console.log('Global -- beforeResolve - fired.')
  if (to.path == '/login') {
    if (Object.keys(to.query).length === 0) {
      // console.log('U GO TO LOGIN so I remove localstorage')
      localStorage.removeItem('RadiiView-DentiiCloud')
    } else {
      if (to.query.otp && to.query.token && to.query.reference) {
        // check query 'updateDoctorID', if exist Let's show RequestInfoDialog
        if (to.query.updateDoctorID) {
          store.commit('SET_SHOW_REQUEST_INFO', { isShowRequestInfo: true })
        }

        // for click link from otp email.
        var jsonObj = JSON.stringify(jwt_decode(to.query.token))
        jsonObj = JSON.parse(jsonObj)
        var loginParams = {
          username: jsonObj.User_Email,
          password: to.query.otp,
          appID: 'CTManagementID',
          reference: to.query.reference
        }
        requestLoginToken(loginParams).then(res => {
          // console.log(res.status)
          // _this.logining = false;
          if (res.status !== 200) {
            // console.log('Login fail!')
          } else {
            const token = res.data.token
            const tokenJsonObj = jwt_decode(token)
            var radiiViewInfo = {
              user: tokenJsonObj,
              authentication: token
            }
            localStorage['RadiiView-DentiiCloud'] = JSON.stringify(radiiViewInfo)
            // var parts = window.location.hostname.split('.')
            // var domain = parts.length === 3 ? parts[1] + '.' + parts[2] : window.location.hostname
            Vue.$cookies.set('user_session', token, '30min', null, window.location.hostname)
            next({ path: '/patients' })
          }
        })
      } else {
        next({ path: '/login' })
      }
    }
  }

  const user = isMobile ? localStorage['RadiiView-DentiiCloud'] : Vue.$cookies.isKey('user_session')
  if (!user && to.path != '/login') {
    next({ path: '/login' })
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
