<template>
  <div>
      <v-app-bar
        absolute
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
        :color="color"
        :dense="true"
        dark
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>{{titleName}}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-icon class="pr-1" medium dark>{{icons.mdiAccountCircle}}</v-icon>
        <span class="pr-2">{{username}}</span>
        <!-- <div v-if="$vuetify.breakpoint.smAndUp" class="pr-2 text--center"> -->
        <div v-if="platform && $vuetify.breakpoint.smAndUp" class="pr-2 text--center">
          <v-btn small color="white" class="blue--text" @click="openPlatform()"><img src="@/assets/icon_dental.png" class="pr-2" style="width: 28px;">Go to Platform</v-btn>
        </div>
        <v-btn small outlined color="white" @click="handleLogOut()">Log out</v-btn>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-img src="icon/PWA/DentiiCloudIcon_96x96.png"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{titleName}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense>

            <v-list-item
              v-for="item in $router.options.routes[0].children"
              :key="item.name"
              :to="{path: item.path}"
              link
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- <v-list-item v-if="!$vuetify.breakpoint.smAndUp" link @click="openPlatform()"> -->
            <v-list-item v-if="platform" link @click="openPlatform()">
              <v-list-item-icon>
                <img src="@/assets/icon_dental.png" style="width: 28px;">
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ 'Go to Platform' }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-for="item in items"
              :key="item.name"
              link
              @click="item.handle"
            >
              <v-list-item-icon>
                <v-icon v-if="item.name.includes('About')">{{ item.icon }}</v-icon>
                <img v-else src="@/assets/DentLogo.png" style="height: 28px ;width: 26px;">
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>
        </v-list>
      </v-navigation-drawer>
  </div>
</template>

<script>
import { mdiAccountCircle, mdiHelpCircle, mdiAlphaDCircle } from '@mdi/js'
import { postLinkToPlatform } from '@/api/api'

export default {
  props: { source: String },
  data () {
    return {
      icons: {
        mdiAccountCircle
      },
      titleName: 'RadiiView-DentiiCloud',
      drawer: false,
      username: '',
      useremail: '',
      color: 'blue darken-2',
      items: [{ name: 'OTP for DentiPlan', icon: mdiAlphaDCircle, handle: this.onDentiPlanOTP }, { name: 'About', icon: mdiHelpCircle, handle: this.onAboutDialog }]
    }
  },
  methods: {
    handleLogOut () {
      this.$router.push({ path: '/login' })
    },
    onAboutDialog () {
      this.drawer = false
      this.$store.commit('SET_ABOUT_SHOW', { aboutShow: true })
    },
    onDentiPlanOTP () {
      this.drawer = false
      this.$store.commit('SET_REQUEST_OTP_SHOW', { requestOTPShow: true })
    },
    openPlatform () {
      const user = JSON.parse(localStorage['RadiiView-DentiiCloud']).user
      const reqInfo = {
        email: user.User_Email
        // doctor_id: '12356987541'
      }
      // console.log('G o : ' + user.User_Email)
      postLinkToPlatform(reqInfo).then(res => {
        if (res.status == 200) {
          window.open(res.data.message)
        } else {
          this.$store.commit('SET_SHOW_REQUEST_INFO', { isShowRequestInfo: true })
        }
      })
    }
  },
  mounted () {
    switch (this.machine.toLowerCase()) {
      case 'bodiiray':
        this.titleName = 'RadiiView'
        this.items.shift()
        break
      default:
        break
    }
    const user = JSON.parse(localStorage['RadiiView-DentiiCloud']).user
    this.username = user.User_Name
    this.drawer = false
    // console.log(this.$router)
    if (user.ROLE_ID == 4) { // SUBDOCTOR
      this.color = 'green darken-3'
    }
    this.$router.push({ path: '/patients' })
  },
  computed: {
    platform () {
      return this.$store.state.baseURL.platform
    },
    machine () {
      return this.$store.state.baseURL.machine
    }
  }
}
</script>
