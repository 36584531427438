<template>
  <div class="text-center">
    <v-dialog
      v-model="aboutShow"
      max-width='min-content'
      persistent
      scrollable
    >
      <v-card>
        <v-card-title
          class="headline primary white--text"
          primary-title
        >
          About
        </v-card-title>

        <v-card-text class="pt-6" style="min-width: 25vw;">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{titleName}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Version: 2.4.4</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Released Date: 2021-11-01 09:55</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="onShowDialog(false)"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'AboutVersion',
  props: {
    aboutShow: Boolean
  },
  data () {
    return {
      dialog: false,
      titleName: 'RadiiView-DentiiCloud'
    }
  },
  mounted () {
    switch (this.machine.toLowerCase()) {
      case 'bodiiray':
        this.titleName = 'RadiiView'
        break
      default:
        break
    }
  },
  methods: {
    onShowDialog (val) {
      this.$store.commit('SET_ABOUT_SHOW', { aboutShow: val })
    }
  },
  computed: {
    machine () {
      return this.$store.state.baseURL.machine
    }
  }
}

</script>

<style>
</style>
