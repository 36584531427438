<template>
    <v-flex>
        <v-card flat class="pa-1">
          <template>
            <v-file-input
              v-model="file"
              id="file"
              ref="file"
              accept=".dtp"
              label="Planning file"
              show-size
              chips
              :counter="true"
              :prepend-icon="icons.mdiFolderUpload">
            </v-file-input>
            <v-btn
              :loading="isUploading"
              :disabled="file.length == 0"
              id="btn-upload"
              color="blue-grey"
              class="ma-2 white--text"
              @click="onUpload()"
            >
              Upload
              <v-icon right dark>{{icons.mdiCloudUpload}}</v-icon>
            </v-btn>
          </template>
        </v-card>
    </v-flex>
</template>

<script>
import { mdiFolderUpload, mdiCloudUpload } from '@mdi/js'
import { postUploadFile, handleExpireSession } from '@/api/api'
export default {
  props: ['patient_Info'],
  data () {
    return {
      icons: { mdiFolderUpload, mdiCloudUpload },
      file: [],
      isUploading: false
    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    handleFilesUpload () {
      this.file = this.$refs.file.files[0]
      // console.log(this.file)
    },
    onUpload () {
      var _this = this
      _this.isUploading = true
      var userInfo = JSON.parse(localStorage['RadiiView-DentiiCloud']).user
      var formData = new FormData()
      const scan_date = new Date(this.$store.state.patientSelected.scan_date)
      scan_date.setUTCMinutes(0, 0, 0)
      scan_date.setUTCHours(0)
      formData.append('file', _this.file)
      formData.append('serieUID', _this.patient_Info.Ctdata_SerieUID)
      formData.append('uploaderInfo', `{"user_id": "${userInfo.USER_ID}","ROLE_TYPE": "${userInfo.ROLE_ID}"}`)
      formData.append('scanDate', scan_date.toISOString())
      postUploadFile(formData).then((res) => {
        // eslint-disable-next-line no-mixed-operators
        if (res.status == 200 || res.status == 202) {
          const registerObject = { isSuccess: true, Info: res }
          _this.$emit('onUploaded', registerObject)
          _this.isUploading = false
          _this.onCancel()
        }
      }).catch(function (err) {
        handleExpireSession(err, _this)
      })
    },
    onCancel () {
      this.file = []
    }
  }
}
</script>

<style scoped>
  .flex-input-file {
    border: 2px dashed rgb(52, 142, 226);
  }
  .input-file {
    padding: 16px;
  }
  /* .input-file::-webkit-file-upload-button {
    visibility: hidden;
  } */
</style>
