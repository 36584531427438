<template>
  <div class="text-center">
    <v-dialog
      v-model="sessionShow"
      persistent
      scrollable
      max-width='max-content'
    >
      <v-card color="error" dark>
        <v-card-title
        ><v-icon>{{icons.mdiShieldLockOutline}}</v-icon>
          Session expired !!
        </v-card-title>

        <v-card-text>
          Please sign in again.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="onShowDialog(false)"
          >
            Sign in
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- <v-alert
        :icon="icons.mdiShieldLockOutline"
        prominent
        type="info"
        style="margin-bottom: 0;"
      >
        Session expired !! Please sign
      </v-alert> -->
    </v-dialog>
  </div>
</template>

<script>
import { mdiShieldLockOutline } from '@mdi/js'
export default {
  name: 'AboutVersion',
  props: {
    sessionShow: Boolean
  },
  data () {
    return {
      dialog: false,
      icons: { mdiShieldLockOutline }
    }
  },
  methods: {
    onShowDialog (val) {
      this.$store.commit('SET_SHOW_SESSION', { isShowSession: val })
      this.$router.push('/login')
    }
  }
}

</script>

<style>
</style>
