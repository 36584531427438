<template>
  <div class="text-center">
    <v-dialog
      v-model="requestOTPShow"
      max-width='max-content'
      persistent
      scrollable
    >
      <v-card>
        <v-card-title
          class="headline primary white--text"
          primary-title
        >
          OTP for DentiPlan
          <v-spacer/>
          <v-tooltip top>
          <template v-slot:activator="{on}">
            <v-btn
              small
              rounded
              @click="handleSubmitReqOTP"
            >
              <v-icon v-on="on">{{ icons.mdiReload }}</v-icon>
            </v-btn>
          </template>
          <span>{{'Request OTP again'}}</span>
          </v-tooltip>
        </v-card-title>

        <v-progress-linear indeterminate height="4" v-if="loading"></v-progress-linear>

        <v-card-text class="pt-6">
          <v-text-field
            :value="refID"
            label="Ref"
            outlined
            readonly
            ref="refToCopy"
          >
            <template slot="append">
              <v-tooltip top>
              <template v-slot:activator="{on}">
                <v-icon v-on="on" @click="copyText('refToCopy')">{{icons.mdiContentCopy}}</v-icon>
              </template>
              <span>{{'Copy to clipboard'}}</span>
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field
            :value="password"
            label="OTP"
            outlined
            readonly
            ref="otpToCopy"
          >
            <template slot="append">
              <v-tooltip top>
              <template v-slot:activator="{on}">
                <v-icon v-on="on" @click="copyText('otpToCopy')">{{icons.mdiContentCopy}}</v-icon>
              </template>
              <span>{{'Copy to clipboard'}}</span>
              </v-tooltip>
            </template>
          </v-text-field>

          <h2 class="text-center" id="time"></h2>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="onShowDialog(false)"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <vsnackbar :snackbar="snackbar" />
  </div>
</template>

<script>
import { mdiReload, mdiOnepassword, mdiKeyChain, mdiContentCopy } from '@mdi/js'
import { requestLoginOTPDentiPlan } from '@/api/api'
import vsnackbar from '@/components/Widgets/v-snackbar'

export default {
  name: 'requestOTP',
  components: { vsnackbar },
  props: {
    requestOTPShow: Boolean
  },
  data () {
    return {
      dialog: false,
      email: '',
      icons: { mdiReload, mdiOnepassword, mdiKeyChain, mdiContentCopy },
      loading: false,
      refID: '',
      password: '',
      copied1: false,
      copied2: false,
      intervalCountDown: null,
      snackbar: {
        show: false,
        message: '',
        colorValue: 'success',
        timeout: 5000
      }
    }
  },
  methods: {
    onShowDialog (val) {
      this.$store.commit('SET_REQUEST_OTP_SHOW', { requestOTPShow: val })
    },
    handleSubmitReqOTP () {
      const _self = this
      var userInfo = JSON.parse(localStorage['RadiiView-DentiiCloud']).user
      _self.email = userInfo.User_Email
      _self.refID = ''
      _self.password = ''
      var emailParams = {
        email: _self.email,
        key: 'dentiiscanstaff'
      }
      _self.loading = true
      requestLoginOTPDentiPlan(emailParams).then(res => {
        _self.loading = false
        if (res.status !== 200) {
          _self.snackbar.show = true
          _self.snackbar.colorValue = 'error'
          _self.snackbar.message = 'Request OTP failed !!'
        } else {
          _self.refID = res.data.reference
          _self.password = res.data.otp
          _self.updateCountDownTime(res.data.expired_date)
          _self.snackbar.show = true
          _self.snackbar.colorValue = 'success'
          _self.snackbar.message = 'Request OTP Successful !!'
        }
        // eslint-disable-next-line handle-callback-err
      }).catch(function (error) {
        _self.logining = false
        _self.snackbar.show = true
        _self.snackbar.colorValue = 'error'
        _self.snackbar.message = 'Request OTP failed !!'
      })
    },
    copyText (val) {
      const textToCopy = this.$refs[val].$el.querySelector('input')
      textToCopy.select()
      textToCopy.setSelectionRange(0, 99999)
      document.execCommand('copy')
    },
    updateCountDownTime (dateTime) {
      const _self = this
      clearInterval(_self.intervalCountDown)
      document.getElementById('time').innerHTML = ''
      const countDownDate = new Date(dateTime).getTime()
      // Update the count down every 1 second
      _self.intervalCountDown = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime()

        // Find the distance between now and the count down date
        var distance = countDownDate - now

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)

        // Display the result in the element with id="time"
        document.getElementById('time').innerHTML = minutes + 'm ' + seconds + 's '

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(_self.intervalCountDown)
          document.getElementById('time').innerHTML = 'EXPIRED !!'
        }
      }, 1000)
    }
  },
  watch: {
    requestOTPShow (val) {
      if (val) {
        this.handleSubmitReqOTP()
      }
    }
  }
}

</script>
