<template>
    <v-card flat>
        <v-list class="scroll-y">
          <v-list-item v-for="doctor in doctor_lists" :key="doctor.title" @click="selectedDoctor(doctor)">
            <v-list-item-content :id="'selector-name-' + doctor.name">
              <v-list-item-title v-text="doctor.name"></v-list-item-title>
            </v-list-item-content>
            <v-spacer></v-spacer>
            <v-list-item-avatar>
              <v-icon v-if="doctor.forward_status" large color="success">{{icons.mdiAccountCheck}}</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <center v-if="doctor_lists.length == 0">No person.</center>
        </v-list>
    </v-card>
</template>

<script>
import { mdiAccountCheck } from '@mdi/js'
import { handleExpireSession } from '@/api/api'
export default {
  props: ['doctor_lists'],
  data () {
    return {
      icons: { mdiAccountCheck }
    }
  },
  methods: {
    selectedDoctor (val) {
      var _this = this
      this.$emit('selectedDoctor', val)
    }
  }
}
</script>

<style>
</style>
