import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    aboutShow: false,
    requestOTPShow: false,
    baseURL: '',
    socketScan: '',
    socket: {
      isConnected: false,
      error: '',
      message: '',
      reconnectError: ''
    },
    patientSelected: {
      doctor_ID_Clinic: '',
      patient_ID_Clinic: '',
      hn: '',
      patient_name: '',
      description: '',
      doctor_name: '',
      Ctdata_SerieUID: '',
      scan_date: '',
      Ctmode_Description: ''
    },
    isShowRequestInfo: false,
    isShowSession: false,
    consentShow: false
  },
  mutations: {
    SET_PATIENT_SELECTED (state, payload) {
      state.patientSelected = payload
    },
    SET_BASE_URL (state, payload) {
      state.baseURL = payload.baseURL
    },
    SET_REQUEST_OTP_SHOW (state, payload) {
      // console.log('payload.requestOTPShow : ' + payload.requestOTPShow)
      state.requestOTPShow = payload.requestOTPShow
      // console.log('state.requestOTPShow : ' + state.requestOTPShow)
    },
    SET_ABOUT_SHOW (state, payload) {
      state.aboutShow = payload.aboutShow
    },
    SET_SHOW_REQUEST_INFO (state, payload) {
      state.isShowRequestInfo = payload.isShowRequestInfo
    },
    SET_SHOW_SESSION (state, payload) {
      state.isShowSession = payload.isShowSession
    },
    SET_CONSENT_SHOW (state, payload) {
      state.consentShow = payload.consentShow
    },
    SET_SOCKET_SCAN (state, payload) {
      state.socketScan = payload.socketScan
    },
    SOCKET_ONOPEN (state, event) {
      Vue.prototype.$socket = event.currentTarget
      state.socket.isConnected = true
    },
    SOCKET_ONCLOSE (state, event) {
      state.socket.isConnected = false
    },
    SOCKET_ONERROR (state, event) {
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE (state, message) {
      // console.log(message)
      state.socket.message = message
      state.socketScan = JSON.parse(message.data)
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT (state, count) {
      // console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR (state) {
      state.socket.reconnectError = true
    }
  },
  getters: {
    getShowRequestInfo (state) {
      return state.isShowRequestInfo
    }
  },
  actions: {
  },
  modules: {
  }
})
