<template>
  <div class="text-center">
    <v-dialog
      v-model="isDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          โปรดอัพเดตข้อมูลของท่าน
        </v-card-title>
        <v-card-text class="pb-0 pt-4">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
              <v-text-field
                v-model="doctorName"
                :rules="doctorNameRules"
                label="ชื่อทันตแพทย์"
                outlined
                required>
              </v-text-field>
              <v-text-field
                v-model="doctorIDClinic"
                :rules="doctorIDClinicRules"
                label="เลขที่ใบประกอบวิชาชีพทันตแพทย์"
                outlined
                required>
              </v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="submitButtonAction(false)">
            Submit
          </v-btn>
          <v-btn color="primary" text @click="skipButtonAction(false)">
            Skip
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <vsnackbar :snackbar="snackbar" />
  </div>
</template>

<script>
import { postUpdateDoctorIDClinic, handleExpireSession } from '@/api/api'
import vsnackbar from '@/components/Widgets/v-snackbar'
export default {
  components: { vsnackbar },
  data () {
    return {
      valid: false,
      doctorName: '',
      doctorNameRules: [
        v => !!v || 'Name is required'
        // v => (v && v.length <= 50) || 'Name must be less than 50 characters'
      ],
      doctorIDClinic: '',
      doctorIDClinicRules: [
        v => !!v || 'ID is required'
        // v => (v && v.length <= 11) || 'ID must be less than 11 characters'
      ],
      snackbar: {
        show: false,
        message: '',
        colorValue: 'success',
        timeout: 5000
      }
    }
  },
  computed: {
    isDialogVisible: function () {
      const isShow = this.$store.getters.getShowRequestInfo
      return isShow
    }
  },
  mounted () {
    var userInfo = JSON.parse(localStorage['RadiiView-DentiiCloud']).user
    this.doctorName = userInfo.User_Name
  },
  methods: {
    skipButtonAction (done) {
      this.$store.commit('SET_SHOW_REQUEST_INFO', { isShowRequestInfo: false })
    },
    submitButtonAction (done) {
      const _self = this
      _self.$refs.form.validate()
      if (_self.valid) {
        const para = {
          doctor_name: _self.doctorName,
          doctor_id_clinic: _self.doctorIDClinic
        }
        postUpdateDoctorIDClinic(para).then((res) => {
          if (res.status == 200) {
            _self.$store.commit('SET_SHOW_REQUEST_INFO', { isShowRequestInfo: false })
            _self.snackbar.show = true
            _self.snackbar.message = 'Success !!'
            _self.snackbar.colorValue = 'success'
          } else {
            _self.snackbar.show = true
            _self.snackbar.message = 'FAIL !!'
            _self.snackbar.colorValue = 'red'
          }
        }).catch(function (err) {
          handleExpireSession(err, _self)
        })
      }
    }
  }
}
</script>
