<template>
    <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout || 5000"
        :top="true"
        :color="snackbar.colorValue"
        :multi-line="true"
    >
        {{snackbar.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
            dark
            text
            v-bind="attrs"
            @click="snackbar.show = false"
          >
            Close
          </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
  props: {
    snackbar: {
      show: Boolean,
      colorValue: String,
      message: String,
      timeout: Number
    }
  }
}
</script>

<style scope>
</style>
