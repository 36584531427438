<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-card>
                    <div class="text-xs-center title font-weight-thin">
                        <v-dialog v-model="myVisibleDialog" persistent max-width='max-content'>
                            <!--v-flex  xs12 -->
                            <v-card
                                color="white"
                                class="headline blue lighten-2 white--text text-xs-center"
                            >
                              <v-card-title>
                                <v-spacer></v-spacer>
                                <span>Selected Data</span>
                                <v-spacer></v-spacer>
                                <v-btn
                                    depressed
                                    small
                                    class="transparent right"
                                    @click="myVisibleDialog = false"
                                ><v-icon>{{icons.mdiClose}}</v-icon></v-btn>
                              </v-card-title>
                            </v-card>
                            <!--/v-flex-->
                            <!--v-flex xs12-->
                            <v-card>
                                <v-card-text class="pt-2 text-xs-left black--text">
                                    HN (Patient ID): {{patientInfo.patient_ID_Clinic}}
                                    <br />
                                    Patient's Name: {{patientInfo.patient_name}}
                                    <br />
                                    Description: {{patientInfo.description}}
                                    <br />
                                    Doctor's Name: {{patientInfo.doctor_name}}
                                    <br />
                                </v-card-text>

                              <v-divider></v-divider>

                              <v-data-table
                                  :headers="headers"
                                  :items="scanlist"
                                  class="elevation-1"
                                  hide-default-footer
                                  item-key="Ctdata_SerieUID"
                              >
                                <template v-slot:item.view="{}">
                                  <v-icon id="icon-view" medium color="indigo" class="mr-2" @click="openViewer()" :disabled="patient_Info.Reslice_status.toLowerCase() == 'inprogress'">{{icons.mdiEye}}</v-icon>
                                </template>
                                <template v-slot:item.download="{}">
                                  <v-icon id="icon-download" medium color="indigo" class="mr-2" @click="openDownload()">{{icons.mdiCloudDownload}}</v-icon>
                                </template>
                                <template v-slot:item.forward="{}">
                                  <v-icon id="icon-forward" color="indigo" medium class="mr-2" @click="openForward()">{{icons.mdiShare}}</v-icon>
                                </template>
                                <template v-slot:item.planning="{}">
                                  <v-icon id="icon-forward" color="indigo" medium class="mr-2" @click="openUpload()">{{icons.mdiSwapVerticalBold}}</v-icon>
                                </template>
                              </v-data-table>
                            </v-card>
                        </v-dialog>
                    </div>
                </v-card>
            </v-flex>
        </v-layout>
        <vsnackbar :snackbar="snackbar" />
    </v-container>
</template>

<script>
import { getScanList, getViewerURL, getDownloadURL, handleExpireSession, getCodeName, getHospName, getMachine, getDownloadDICOMURL } from '@/api/api'

import {
  mdiClose,
  mdiEye,
  mdiShare,
  mdiSwapVerticalBold,
  mdiCloudDownload
} from '@mdi/js'
import vsnackbar from '@/components/Widgets/v-snackbar'
export default {
  props: ['setVisibleDownloadDiglog', 'downloadDialogVisible', 'setVisibleForwardDialog', 'patient_Info', 'setVisibleUploadDialog'],
  components: { vsnackbar },
  data () {
    return {
      icons: { mdiClose, mdiEye, mdiShare, mdiSwapVerticalBold, mdiCloudDownload },
      myVisibleDialog: false,
      color: '',
      // ,isZipping : false
      // ,status : ''
      // ,statusDisplayToUser : ''
      // ,disableDownload : true
      // ,dialogTitle : ''
      // ,url_link: ''
      scanlist: [],
      patientInfo: {},
      headers: [
        { text: 'View', value: 'view', sortable: false, align: 'center', width: '25%' },
        { text: 'Download', value: 'download', sortable: false, align: 'center', width: '25%' }
        // { text: 'Forward to doctors', value: 'forward' },
        // { text: 'Planing', value: 'planing' }
      ],
      forwardIsShow: false,
      planingIsShow: false,
      snackbar: {
        show: false,
        message: '',
        colorValue: 'success',
        timeout: 5000
      }
    }
  },
  watch: {
    // JUST-LIKE INTERFACE
    downloadDialogVisible: function (val) {
      this.myVisibleDialog = val
    },
    patient_Info: function (val) {
      // console.log(val)
      this.patientInfo = val
      var userInfo = JSON.parse(localStorage['RadiiView-DentiiCloud']).user
      if ((userInfo.ROLE_ID == 4 || this.patient_Info.User_Email != userInfo.User_Email) && userInfo.ROLE_ID != 1) {
        this.headers.length = 6
        this.headers.push({ text: 'Upload/Download Plan', value: 'planning', sortable: false, align: 'center', width: '25%' })
        this.forwardIsShow = false
        this.planingIsShow = true
      } else {
        this.headers.length = 6
        this.headers.push({ text: 'Forward DICOM', value: 'forward', sortable: false, align: 'center', width: '25%' }, { text: 'Upload/Download Plan', value: 'planning', sortable: false, align: 'center', width: '25%' })
        this.forwardIsShow = true
        this.planingIsShow = true
      }

      if (this.machine.toLowerCase().includes('bodiiray')) {
        this.headers.pop()
      }

      // console.log("SHOW DIALOG INFO")
      // this.dialogTitle = 'Download DICOM'

      if (this.myVisibleDialog == true) {
        // this.disableDownload = true
        this.getScans(this.patientInfo.patient_ID_Clinic)
      }
    },
    myVisibleDialog (val) {
      // Notify Parent that I'm close
      if (val === false) {
        this.setVisibleDownloadDiglog(false)
      }
    }
  },
  methods: {
    handleClose (done) {
      // console.log("handelClose...")
      this.setVisibleDownloadDiglog(false)
    },
    openViewer () {
      // console.log("CT DATA seriesUID : " + this.patientInfo.Ctdata_SerieUID)
      // console.log("link : " + this.patientInfo.Ctdata_SerieUID);
      const _self = this
      var date = _self.patientInfo.scan_date.split(' ')[0]
      var time = _self.patientInfo.scan_date.split(' ')[1]
      const scan_date = new Date(date).toISOString()
      let viewer = getViewerURL()
      var radiiviewParam = {
        codeName: getCodeName(),
        hospName: getHospName(),
        scanDate: scan_date,
        token: JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication
      }
      var parts = window.location.hostname.split('.')
      var domain = parts.length === 3 ? parts[1] + '.' + parts[2] : window.location.hostname
      _self.$cookies.set('radiiviewParam', radiiviewParam, '30min', null, domain)
      if (_self.patient_Info.Ctmode_Description.toLowerCase().includes('panoramic') || _self.machine.toLowerCase().includes('bodiiray')) {
        viewer = process.env.NODE_ENV === 'production' ? viewer + '/2d/' : 'http://localhost:8080/'
      } else { // IS CT
        viewer = process.env.NODE_ENV === 'production' ? viewer + '/3d/' : 'http://localhost:8080/'
      }
      window.open(viewer + '?serieUID=' + this.patientInfo.Ctdata_SerieUID + '&hospName=' + radiiviewParam.hospName, '_blank')
    },
    openDownload () {
      const _self = this
      var date = _self.patientInfo.scan_date.split(' ')[0]
      const scan_date = new Date(date).toISOString()
      const params = {
        hospital_id: getCodeName(),
        scan_date: scan_date,
        serie_uid: _self.patientInfo.Ctdata_SerieUID
      }
      getDownloadDICOMURL(params).then((res) => {
        if (res.status == 200) {
          window.open(res.data.url)
        } else {
          _self.snackbar.show = true
          _self.snackbar.message = 'Download DICOM failed !!'
          _self.snackbar.colorValue = 'red'
        }
      }).catch(function () {
        _self.snackbar.show = true
        _self.snackbar.message = 'Download DICOM failed !!'
        _self.snackbar.colorValue = 'red'
      })
    },
    getScans () {
      const user = JSON.parse(localStorage['RadiiView-DentiiCloud']).user
      // console.log('Ctdata_SerieUID : ' + this.patientInfo.Ctdata_SerieUID)
      // console.log(JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication)
      const _self = this
      const para = {
        ctdata_SerieUID: this.patientInfo.Ctdata_SerieUID,
        user: JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication
      }
      getScanList(para).then((res) => {
        this.scanlist = res.data
        // console.log(this.scanlist)
      }).catch(function (err) {
        handleExpireSession(err, _self)
      })
    },
    openForward () {
      this.setVisibleDownloadDiglog(false)
      this.setVisibleForwardDialog(true)
    },
    openUpload () {
      this.setVisibleDownloadDiglog(false)
      this.setVisibleUploadDialog(true)
    }
  },
  computed: {
    machine () {
      return this.$store.state.baseURL.machine
    }
  }
}
</script>
