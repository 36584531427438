<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>

            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-spacer></v-spacer>
                <v-icon large color="white darken-2">{{icons.mdiHome}}</v-icon>
                <v-toolbar-title>{{titleName}}</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-progress-linear indeterminate v-if="loading" height="4"></v-progress-linear>

              <v-card-text class="text-subtitle-1 text-center pb-2">
                ลงชื่อเข้าใช้ เพื่อดำเนินการต่อ
              <div class="formButtonButtom pt-2">
                <v-btn id="btn-gglogin" dark class="blue  mb-3" @click="socialGoogleLogin">
                  <v-icon class="pr-1">{{icons.mdiEmail}}</v-icon>
                  Sign in</v-btn>
              </div>
              </v-card-text>
              <!-- <div class="text-center pb-3">
                <v-btn class="text-decoration-underline" plain color="blue" @click="openPolicyLink()">
                  นโยบายคุ้มครองข้อมูลส่วนบุคคลของ สวทช.
                </v-btn>
              </div> -->
            </v-card>

          </v-flex>
        </v-layout>
      </v-container>

      <vsnackbar :snackbar="snackbar" />

    </v-content>
  </v-app>
</template>

<style src="../assets/styles.css" scoped></style>

<script>
import {
  requestLoginToken,
  requestLoginOTP,
  postVertifyToken
} from '../api/api'
import jwt_decode from 'jwt-decode'
import db from './FirebaseConfig/firebaseInit'
import firebase from 'firebase'
import vsnackbar from '@/components/Widgets/v-snackbar'
import {
  mdiAccount,
  mdiHome,
  mdiOnepassword,
  mdiKeyChain,
  mdiEmail
} from '@mdi/js'

export default {
  components: { vsnackbar },
  mounted: function () {
    // Clean System memory
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    localStorage.clear()
    this.$cookies.remove('user_session', null, window.location.hostname)
    switch (this.machine.toLowerCase()) {
      case 'bodiiray':
        this.titleName = 'RadiiView'
        break
      default:
        break
    }
  },
  filters: {
    capitalize (val) {
      return val.toUpperCase()
    }
  },
  data () {
    return {
      icons: {
        mdiHome,
        mdiAccount,
        mdiOnepassword,
        mdiKeyChain,
        mdiEmail
      },
      drawer: null,
      account: '',
      checkPass: '',
      loading: false,
      snackbar: {
        show: false,
        message: '',
        colorValue: 'success',
        timeout: 5000
      },
      snackbar_massage: '',
      reqOTP: false,
      refID: '',
      titleName: 'RadiiView-DentiiCloud'
    }
  },
  methods: {
    socialGoogleLogin: function () {
      var _this = this
      const provide = new firebase.auth.GoogleAuthProvider().addScope('email')
      firebase.auth().signInWithPopup(provide)
        .then(result => {
          const obj = {
            google_id: result.additionalUserInfo.profile.id,
            fullname: result.additionalUserInfo.profile.name,
            email: result.additionalUserInfo.profile.email,
            profile_image: result.additionalUserInfo.profile.picture,
            user_type_id: 1
          }
          // console.log(obj)

          firebase.auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then(function (idToken) {
              var tokenParams = {
                idToken: idToken
              }
              postVertifyToken(tokenParams).then(res => {
                _this.snackbar.show = true
                _this.snackbar.colorValue = 'success'
                _this.snackbar.message = 'Login Successful !!'
                const token = res.data.token
                const tokenJsonObj = jwt_decode(token)
                var radiiViewInfo = {
                  user: tokenJsonObj,
                  authentication: token
                }
                localStorage['RadiiView-DentiiCloud'] = JSON.stringify(radiiViewInfo)
                // var parts = window.location.hostname.split('.')
                // var domain = parts.length === 3 ? parts[1] + '.' + parts[2] : window.location.hostname
                _this.$cookies.set('user_session', token, '30min', null, window.location.hostname)
                _this.$router.push({
                  path: '/patients'
                })
              // eslint-disable-next-line handle-callback-err
              }).catch(function (error) {
                _this.loading = false
                _this.snackbar.show = true
                _this.snackbar.colorValue = 'error'
                _this.snackbar.message = 'Incorrect username or password !!'
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch(function (error) {
              // Handle error get token
              // console.log(error)
            })
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          // console.log(err)
          // console.log('Auth Fail, Nothing happen')
        })
    },
    handleSubmitLoginOTP (ev) {
      if (this.$refs.form1.validate() && this.$refs.form2.validate()) {
        var _this = this
        _this.loading = true
        var loginParams = {
          username: _this.account,
          reference: _this.refID,
          password: _this.checkPass,
          appID: 'CTManagementID'
        }
        requestLoginToken(loginParams).then(res => {
          _this.loading = false
          if (res.status !== 200) {
            _this.snackbar.show = true
            _this.snackbar.colorValue = 'error'
            _this.snackbar.message = 'Login failed !!'
          } else {
            _this.snackbar.show = true
            _this.snackbar.colorValue = 'success'
            _this.snackbar.message = 'Login Successful !!'
            const token = res.data.token
            const tokenJsonObj = jwt_decode(token)
            var radiiViewInfo = {
              user: tokenJsonObj,
              authentication: token
            }
            localStorage['RadiiView-DentiiCloud'] = JSON.stringify(radiiViewInfo)
            // var parts = window.location.hostname.split('.')
            // var domain = parts.length === 3 ? parts[1] + '.' + parts[2] : window.location.hostname
            _this.$cookies.set('user_session', token, '30min', null, window.location.hostname)
            _this.$router.push({
              path: '/patients'
            })
          }
        // eslint-disable-next-line handle-callback-err
        }).catch(function (error) {
          _this.loading = false
          _this.snackbar.show = true
          _this.snackbar.colorValue = 'error'
          _this.snackbar.message = 'Incorrect username or password !!'
        })
      }
    },
    handleSubmitReqOTP (ev) {
      //  this.reqOTP = !this.reqOTP
      if (this.$refs.form1.validate()) {
        var _this = this
        _this.loading = true
        var emailParams = {
          email: _this.account,
          key: 'dentiiscanstaff'
        }
        requestLoginOTP(emailParams).then(res => {
          _this.loading = false
          if (res.status !== 200) {
            _this.snackbar.show = true
            _this.snackbar.colorValue = 'error'
            _this.snackbar.message = 'Request OTP failed !! (Please use valid registered email in DentiiScan)'
          } else {
            _this.reqOTP = true
            _this.refID = res.data.reference
            _this.snackbar.show = true
            _this.snackbar.colorValue = 'success'
            _this.snackbar.message = 'Request OTP Successful !! Please check your email: ' + _this.account
          }
        // eslint-disable-next-line handle-callback-err
        }).catch(function (error) {
          _this.loading = false
          _this.snackbar.show = true
          _this.snackbar.colorValue = 'error'
          _this.snackbar.message = 'Request OTP failed !! (Please use valid registered email in DentiiScan)'
        })
      }
    },
    openPolicyLink () {
      window.open('https://www.nstda.or.th/home/nstda-privacy-policy/', '_blank')
    }
  },
  computed: {
    machine () {
      return this.$store.state.baseURL.machine
    }
  }
}

</script>
