<template>
  <div class="text-center">
    <v-dialog
      v-model="consentShow"
      max-width='max-content'
      persistent
      scrollable
    >
      <v-card>
        <v-card-title
          class="headline primary white--text"
          primary-title
        >
          เอกสารแสดงความยินยอมการเข้ารับบริการ RadiiView: Cloud-Based Viewer & Teleconsultation
        </v-card-title>

        <v-card-text class="pt-6">
          <span v-html="text"></span>
          <v-checkbox
            class="text-center"
            v-model="checkbox"
            :label="`ข้าพเจ้าตกลงยินยอมตามข้อกำหนดข้างต้น`"
            color="success"
          ></v-checkbox>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            color="red"
            @click="dialogCancel = true"
          >
            ยกเลิก
          </v-btn>
          <v-btn
            :disabled='!checkbox'
            color="primary"
            @click="onSubmit(false)"
          >
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCancel" max-width="500px">
      <v-card>
        <v-card-title class="text-center text-h6">ยืนยันการยกเลิก ระบบจะถูกลงชื่อออกโดยอัตโนมัติ</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogCancel = false">ยกเลิก</v-btn>
          <v-btn color="blue darken-1" text @click="onCancel(false)">ยืนยัน</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getConsentText, postConsentStatus } from '@/api/api'

export default {
  name: 'ConsentForm',
  props: {
    consentShow: Boolean
  },
  data () {
    return {
      dialog: false,
      checkbox: false,
      text: '',
      dialogCancel: false
    }
  },
  methods: {
    onSubmit (val) {
      const _self = this
      var userInfo = JSON.parse(localStorage['RadiiView-DentiiCloud']).user
      var params = {
        email: userInfo.User_Email,
        filename: '',
        confirm: true
      }
      postConsentStatus(params)
        .then(res => {
          _self.patients = res.data
          _self.table_loading = false
        })
      _self.$store.commit('SET_CONSENT_SHOW', { consentShow: val })
    },
    onCancel (val) {
      this.$store.commit('SET_CONSENT_SHOW', { consentShow: val })
      this.$router.push({ path: '/login' })
    }
  },
  mounted () {
    const _self = this
    getConsentText()
      .then(res => {
        if (res.status === 200) {
          _self.text = res.data
        }
      })
  }
}

</script>

<style>
</style>
