<template>
  <div id="app">
    <v-app id="inspire">
        <MainToolBar/>
        <v-content>
            <router-view></router-view>
        </v-content>
        <RequestOTP :requestOTPShow="requestOTPShow" />
        <AboutVersion :aboutShow="aboutShow" />
        <AlertSession :sessionShow="isShowSession" />
        <ConsentForm :consentShow="consentShow" />
        <RequestInfoDialog></RequestInfoDialog>
    </v-app>
  </div>
</template>

<script>
import MainToolBar from '@/components/ToolBar/toolBar'
import RequestOTP from '@/components/OTP/requestOTP'
import AboutVersion from '@/components/About/aboutVersion'
import AlertSession from '@/components/Alert/alertSession'
import ConsentForm from '@/components/Alert/consentForm'
import RequestInfoDialog from '@/components/Alert/RequestInfoDialog'
import { getConsentStatus } from '@/api/api'

export default {
  components: { MainToolBar, RequestOTP, AboutVersion, AlertSession, RequestInfoDialog, ConsentForm },
  computed: {
    requestOTPShow () {
      return this.$store.state.requestOTPShow
    },
    aboutShow () {
      return this.$store.state.aboutShow
    },
    isShowSession () {
      return this.$store.state.isShowSession
    },
    consentShow () {
      return this.$store.state.consentShow
    }
  },
  methods: {
    consentValidation () {
      const _self = this
      var userInfo = JSON.parse(localStorage['RadiiView-DentiiCloud']).user
      var params = {
        email: userInfo.User_Email
      }
      getConsentStatus(params)
        .then(res => {
          if (res.status === 200) {
            _self.$store.commit('SET_CONSENT_SHOW', { consentShow: false })
          } else if (res.status === 202) {
            _self.$store.commit('SET_CONSENT_SHOW', { consentShow: true })
          } else {
            this.$router.push({ path: '/login' })
          }
        })
    }
  },
  mounted () {
    this.consentValidation()
  }
}
</script>
