import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { isMobile } from 'mobile-device-detect'
import jwt_decode from 'jwt-decode'

var hospName = ''
var config = {
  headers: {}
}

// API for web
export const getPatientList = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers['x-access-token'] = JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication
  return axios.post(`${store.state.baseURL.base}/scans`, params, config)
}

export const postTokenCheck = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers.Authorization = 'Basic ZGVudGlpc2NhbjpkM250aWlzY2Fuc3RAZmY='
  return axios.post(`${store.state.baseURL.base}/token`, params, config)
}

export const getScanList = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers['x-access-token'] = JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication
  return axios.post(`${store.state.baseURL.base}/scantime`, params, config)
}

export const requestLoginToken = params => {
  return axios.post(`${store.state.baseURL.base}/LoginToken`, params, config)
}

export const requestLoginOTP = params => {
  return axios.post(`${store.state.baseURL.base}/otp/request`, params, config)
}

export const requestLoginOTPDentiPlan = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers['x-access-token'] = JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication
  // console.log("${base} : " + `${base}`)
  return axios.post(`${store.state.baseURL.base}/otp/dentiplan/request`, params, config)
}

export const postVertifyToken = params => {
  config.headers['x-access-token'] = params
  // console.log("Vertify url : " + `${base}/firebase/verify`)
  return axios.post(`${store.state.baseURL.base}/firebase/verify`, params, config)
}

export const getDoctorList = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers['x-access-token'] = JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication
  // console.log("getDoctorList : " + `${base}/doctors?serieUID=` + params.Ctdata_SerieUID)
  return axios.get(`${store.state.baseURL.base}/doctors?serieUID=` + params.Ctdata_SerieUID, config)
}

export const postRegisterSubDoctor = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers['x-access-token'] = JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication
  // console.log("postRegister Doctor :"+ `${base}/doctor/register`)
  return axios.post(`${store.state.baseURL.base}/doctor/register`, params, config)
}

export const postForwardPatient = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers['x-access-token'] = JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication
  // console.log("postForward Patient :" + `${base}/doctor/forwardpatient`)
  return axios.post(`${store.state.baseURL.base}/doctor/forwardpatient`, params, config)
}

export const getFileList = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  delete config.headers.Authorization
  config.headers['x-access-token'] = JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication
  // console.log("config.headers : " + `${JSON.stringify(config.headers)}`)
  // console.log("getFileList : " + `${base}/doctor`)
  return axios.get(`${store.state.baseURL.baseUpload}/bucket/dtp` + `?serieUID=${params}`, config)
}

export const postUploadFile = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers['x-access-token'] = JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication
  config.headers['Content-Type'] = 'multipart/form-data'
  // console.log("config.headers : " + `${JSON.stringify(config.headers)}`)
  // console.log("uploadFile : " + `${base}/doctor`)
  return axios.post(`${store.state.baseURL.baseUpload}/bucket/dtp`, params, config)
}

export const getDownloadPlanURL = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers['x-access-token'] = JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication
  // console.log("getFileList : " + `${base}/doctor`)
  return axios.get(`${store.state.baseURL.baseUpload}/bucket/dtp/stream` + `?serieUID=${params.Ctdata_SerieUID}&fileName=${params.fileName}`, config)
}

export const getDownloadDICOMURL = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers['x-access-token'] = JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication
  return axios.get(`${store.state.baseURL.downloadDicomURL}` + `?hospital_id=${params.hospital_id}&scan_date=${params.scan_date}&serie_uid=${params.serie_uid}`, config)
}

export const postUpdateDoctorIDClinic = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers['x-access-token'] = JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication
  return axios.post(`${store.state.baseURL.base}/doctor/info`, params, config)
}

export const postLinkToPlatform = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers['x-access-token'] = JSON.parse(localStorage['RadiiView-DentiiCloud']).authentication
  return axios.post(`${store.state.baseURL.platformInterfaceURL}/link`, params, config)
}

export const getConsentText = () => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers.Authorization = 'Basic ZGVudGlpc2NhbjpkM250aWlzY2Fuc3RAZmY='
  return axios.get(`${store.state.baseURL.gatewayURL}/consent_txt`, config)
}

export const getConsentStatus = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers.Authorization = 'Basic ZGVudGlpc2NhbjpkM250aWlzY2Fuc3RAZmY='
  return axios.get(`${store.state.baseURL.gatewayURL}/consent/${params.email}`, config)
}

export const postConsentStatus = params => {
  if (!isMobile) {
    handleCookieSession()
  }
  config.headers.Authorization = 'Basic ZGVudGlpc2NhbjpkM250aWlzY2Fuc3RAZmY='
  return axios.post(`${store.state.baseURL.gatewayURL}/consent`, params, config)
}

function getBasebackendApi () {
  return new Promise((resolve, reject) => {
    let frontendDomain = ''
    if (process.env.NODE_ENV === 'production') {
      // console.log('In production mode')
      // frontendDomain = window.location.hostname
      frontendDomain = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
      var hosp = location.hostname
      hospName = hosp.substring(0, hosp.indexOf('.'))
      // console.log("frontendDomain : " + frontendDomain)
      axios.get(frontendDomain + '/baseapi').then(res => {
        if (res.status !== 200) {
          // console.log("FETCH base_api ERROR: ")
        } else {
          const base = res.data.base_api
          let socketURL = base.replace('http', 'ws')
          socketURL = socketURL.replace('CTManagement', 'socket.io')
          const platformInterfaceURL = base.replace('CTManagement', 'platform')
          var baseURL = {
            baseURL: {
              base: base,
              baseUpload: res.data.bucketConnector_api,
              viewerbaseURL: res.data.viewer_url,
              downloadDicomURL: res.data.download_url,
              platformInterfaceURL: platformInterfaceURL,
              socketURL: socketURL,
              codeName: res.data.code_name,
              machine: res.data.machine,
              platform: res.data.platform,
              gatewayURL: res.data.gateway_url
            }
          }
          store.commit('SET_BASE_URL', baseURL)
        }
        resolve(res)
      }).catch(function (error) {
        // console.log(error)
        reject(error)
      })
    } else { // IN DEV
      // console.log('In development mode')
      hospName = 'pn0001'
      const base = 'https://pn0001ct.dentiview.xyz/CTManagement'
      // const base = 'http://localhost:25003/CTManagement'
      // const base = 'https://kphct.dentiview.xyz/CTManagement'
      let socketURL = base.replace('http', 'ws')
      socketURL = socketURL.replace('CTManagement', 'socket.io')
      const platformInterfaceURL = base.replace('CTManagement', 'platform')
      var baseURL = {
        baseURL: {
          base: base,
          baseUpload: 'https://pn0001rc.dentiview.xyz',
          viewerbaseURL: 'https://korn.dentiview.xyz',
          downloadDicomURL: 'https://pn0001rc.dentiview.xyz/bucket/dicom',
          platformInterfaceURL: platformInterfaceURL,
          socketURL: socketURL,
          codeName: 'pn0001',
          machine: 'DENTIISCAN',
          platform: false,
          gatewayURL: 'https://gateway.dentiview.xyz'
        }
      }
      store.commit('SET_BASE_URL', baseURL)
      resolve(baseURL)
    }
  })
}

function getViewerURL () {
  if (!isMobile) {
    handleCookieSession()
  }
  return store.state.baseURL.viewerbaseURL
}

function getDownloadURL () {
  return store.state.baseURL.downloadDicomURL
}

function getCodeName () {
  return store.state.baseURL.codeName
}

function getHospName () {
  return hospName
}

function getMachine () {
  return store.state.baseURL.machine
}

function handleExpireSession (err, vm) {
  if (err.response.status === 406) {
    localStorage.removeItem('RadiiView-DentiiCloud')
    vm.$router.push('/login')
  }
}

function handleCookieSession () {
  if (Vue.$cookies.isKey('user_session')) {
    const cookieToken = Vue.$cookies.get('user_session')
    const tokenJsonObj = jwt_decode(cookieToken)
    const userInfo = JSON.parse(localStorage['RadiiView-DentiiCloud'])
    if (tokenJsonObj.User_Email !== userInfo.user.User_Email) {
      // console.log('cookieToken !== userInfo.authentication')
      Vue.$cookies.remove('user_session')
      localStorage.removeItem('RadiiView-DentiiCloud')
      store.commit('SET_SHOW_SESSION', { isShowSession: true })
    } else {
      // console.log('cookie Pass')
      // var parts = window.location.hostname.split('.')
      // var domain = parts.length === 3 ? parts[1] + '.' + parts[2] : window.location.hostname
      Vue.$cookies.set('user_session', userInfo.authentication, '30min', null, window.location.hostname)
    }
  } else {
    // console.log('Vue.$cookies.isKey()')
    Vue.$cookies.remove('user_session')
    localStorage.removeItem('RadiiView-DentiiCloud')
    store.commit('SET_SHOW_SESSION', { isShowSession: true })
  }
}

export {
  handleCookieSession,
  handleExpireSession,
  getBasebackendApi,
  getViewerURL,
  getDownloadURL,
  getCodeName,
  getHospName,
  getMachine
}
