<template>
    <!-- <div id="app">
    <v-app id="inspire">-->
    <v-container fluid>
        <v-layout row>
            <!-- <v-flex xs12 sm8 md4> -->

            <div class="text-xs-center title font-weight-thin">
                <v-dialog v-model="myVisibleDialog" persistent width="unset">
                    <v-card
                        color="black"
                        class="headline blue lighten-2 white--text text-md-center"
                        primary-title>
                        <v-card-title>
                          <v-btn
                            v-if="InfoInputVisible"
                            id="btn-back"
                            depressed
                            small
                            class="transparent left mb-0 btn-fwd"
                            @click="onListDoctor()">
                            <v-icon>{{icons.mdiChevronLeft}}</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                          <span>Forward DICOM</span>
                          <v-spacer></v-spacer>
                          <v-btn
                            v-if="ListDoctorVisible"
                            id="btn-adddoctor"
                            depressed
                            small
                            class="transparent mb-0 btn-fwd"
                            @click="onAddDoctor()"><v-icon>{{icons.mdiAccountPlus}}</v-icon></v-btn>
                        <v-btn
                            id="btn-closeforward"
                            depressed
                            small
                            class="transparent right mb-0 btn-fwd"
                            @click="myVisibleDialog = false"><v-icon>{{icons.mdiClose}}</v-icon></v-btn>
                        </v-card-title>
                        <ListDoctor
                            v-if="ListDoctorVisible"
                            :doctor_lists="doctor_lists"
                            @onAddDoctorClick="onAddDoctor"
                            @selectedDoctor="onSelectedDoctor"
                        ></ListDoctor>
                        <InfoInput
                            v-if="InfoInputVisible"
                            @onRegister="onRegister"
                            @onForward="onForward"
                            :patient_List="patient_List"
                            :selectedDoctor="selectedDoctor"
                        ></InfoInput>
                    </v-card>
                </v-dialog>
            </div>

            <vsnackbar :snackbar="snackbar" />
            <!-- </v-flex> -->
        </v-layout>
    </v-container>
    <!-- </v-app>
    </div>-->
</template>

<script>
import {
  mdiChevronLeft,
  mdiAccountPlus,
  mdiClose
} from '@mdi/js'
import { getDoctorList, handleExpireSession } from '@/api/api'
import InfoInput from '@/components/ForwardPatient/Info_Input'
import ListDoctor from '@/components/ForwardPatient/list_Doctor'
import vsnackbar from '@/components/Widgets/v-snackbar'
export default {
  props: ['forwardDialogVisible', 'setVisibleForwardDialog', 'patient_List'],
  components: { InfoInput, ListDoctor, vsnackbar },
  data () {
    return {
      icons: { mdiChevronLeft, mdiAccountPlus, mdiClose },
      InfoInputVisible: false,
      ListDoctorVisible: true,
      myVisibleDialog: false,
      doctorLength: 0,
      selectedDoctor: '',

      doctor_lists: [],
      snackbar: {
        show: false,
        message: '',
        colorValue: 'success',
        timeout: 5000
      }

    }
  },
  watch: {
    forwardDialogVisible: function (val) {
      // console.log("forwardDialogVisible..... : " + val)
      if (val) {
        this.getDoctorListStatus(this.patient_List)
      } else {
        this.myVisibleDialog = val
      }
    },
    myVisibleDialog (val) {
      // Notify Parent that I'm close
      if (val === false) {
        this.setVisibleForwardDialog(false)
        this.selectedDoctor = ''
      } else {
        if (this.doctorLength == 0) {
          this.ListDoctorVisible = false
          this.InfoInputVisible = true
        } else {
          this.ListDoctorVisible = true
          this.InfoInputVisible = false
        }
      }
    }

  },
  methods: {
    getDoctorListStatus (patient_List) {
      var _self = this
      getDoctorList(patient_List[0]).then((res) => {
        _self.doctorLength = res.data.length
        _self.doctor_lists = res.data
        this.myVisibleDialog = true
        // this.$emit('doctorLength', res.data.length);
      }).catch(function (err) {
        handleExpireSession(err, _self)
      })
    },
    onSelectedDoctor (val) {
      // console.log(val)
      this.selectedDoctor = val
      this.InfoInputVisible = true
      this.ListDoctorVisible = false
    },
    onAddDoctor () {
      if (this.InfoInputVisible == false) {
        this.InfoInputVisible = true
      }
      if (this.ListDoctorVisible == true) {
        this.ListDoctorVisible = false
      }
      this.selectedDoctor = ''
      // this.InfoInputVisible = true
      // this.ListDoctorVisible = false
    },
    onRegister (val) {
      if (val.isSuccess) {
        this.snackbar.show = true
        this.snackbar.message = 'Forward DICOM Successful !!'
        this.snackbar.colorValue = 'success'
      } else {
        this.snackbar.show = true
        this.snackbar.message = 'Forward DICOM failed !! Doctor is already in system.'
        this.snackbar.colorValue = 'red'
      }
    },
    onForward (val) {
      if (val.isSuccess) {
        this.myVisibleDialog = false
        this.snackbar.show = true
        this.snackbar.message = 'Forward DICOM Successful !!'
        this.snackbar.colorValue = 'success'
        const info = { email: val.email, institution: val.institution, name: val.name }
        let isHaveInArray = false
        for (var index in this.doctor_lists) {
          if (this.doctor_lists[index].email == val.email) {
            isHaveInArray = true
            break
          }
        }
        if (!isHaveInArray) {
          this.doctor_lists.push(info)
        }
        this.doctorLength++
      } else {
        this.snackbar.show = true
        this.snackbar.message = "Forward DICOM failed !! Can't forward patient."
        this.snackbar.colorValue = 'red'
      }
    },
    onDoctorLength (val) {
      this.doctorLength = val
    },
    onListDoctor () {
      this.InfoInputVisible = false
      this.ListDoctorVisible = true
    }
  }
}
</script>

<style scoped>
.btn-fwd {
  min-width: 48px;
}
</style>
