<template>
    <section>
        <div id="app">
            <v-app id="inspire">
                <v-container class="pt-12 px-0" grid-list-sm text-xs-center>
                    <v-layout row wrap align-center>
                        <v-flex d-flex xs12 sm12 md5>
                          <v-flex d-flex xs12 sm12 md12>
                            <div class="indigo--text font-weight-bold headline align-left">Patients</div>
                            <v-text-field
                              class="mt-0 pt-0 pl-4"
                              id="input-patient-search"
                              v-model="search"
                              :append-icon="icons.mdiMagnify"
                              label="Search"
                              single-line
                              clearable
                              hide-details>
                            </v-text-field>
                          </v-flex>
                        </v-flex>

                        <v-flex d-flex xs12 sm12 md3 class="text-center">
                            <v-flex>
                              <v-btn-toggle v-model="period.selectType" rounded color="blue darken-3 white--text">
                                  <v-btn id="btn-today" @click.prevent="setPeriodToday()">Today</v-btn>
                                  <v-btn id="btn-week" @click.prevent="setPeriodWeek()">Week</v-btn>
                                  <v-btn id="btn-period">Period</v-btn>
                              </v-btn-toggle>
                            </v-flex>
                        </v-flex>
                        <v-flex d-flex xs12 sm12 md4 class="pr-4">
                          <v-menu ref="startDateMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="period.startDate"
                              transition="scale-transition" offset-ypwd max-width="290px" min-width="290px"
                              :disabled="period.selectType != 2">
                              <template v-slot:activator="{ on }">
                                <v-text-field id="input-startdate" class="mt-4" slot="activator" v-model="period.startDate" label="Start Date"
                                    persistent-hint :prepend-icon="icons.mdiCalendar" :disabled="period.selectType != 2" v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="period.startDate" @change="setPeriodChoose()" no-title scrollable
                                  @input="$refs.startDateMenu.save(period.startDate)" :max="period.endDate"></v-date-picker>
                          </v-menu>

                          <v-menu ref="endDateMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="period.endDate"
                              transition="scale-transition" offset-y max-width="290px" min-width="290px"
                              :disabled="period.selectType != 2">
                              <template v-slot:activator="{ on }">
                                <v-text-field id="input-enddate" class="mt-4" slot="activator" v-model="period.endDate" label="End date"
                                    persistent-hint :prepend-icon="icons.mdiCalendar" :disabled="period.selectType != 2" v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="period.endDate" @change="setPeriodChoose()" no-title scrollable
                                  @input="$refs.endDateMenu.save(period.endDate)" :min="period.startDate" :max="moment().format('YYYY-MM-DD')"></v-date-picker>
                          </v-menu>
                        </v-flex>

                        <v-flex sm12 v-if="selected.length !== 0">
                          <v-btn class="ml-2 mb-2" @click="selectedDownload" color="primary">Download</v-btn>
                          <v-btn class="ml-2 mb-2" @click="selectedFWDDICOM" color="primary" :disabled="statusFWDList">Forward DICOMs</v-btn>
                        </v-flex>

                        <v-flex sm12>
                          <!-- new table -->
                          <template>
                            <v-data-table
                              v-model="selected"
                              :show-select="this.machine.toLowerCase().includes('bodiiray')"
                              :single-select="false"
                              item-key="Ctdata_SerieUID"
                              :headers="headers"
                              :items="patients"
                              hide-default-footer
                              class="elevation-1"
                              :search="search"
                              :loading="table_loading"
                              :page.sync="pagination.page"
                              :items-per-page="pagination.itemsPerPage"
                              @page-count="pagination.pageCount = $event"
                              @click:row="handleSelectScanList"
                            >
                            <template v-slot:item.reslice="{ item }">
                              <v-progress-circular v-if="item.Reslice_status.toLowerCase() == 'inprogress'" indeterminate color="primary"></v-progress-circular>
                              <v-icon v-if="item.Reslice_status.toLowerCase() == '-' || item.Reslice_status.toLowerCase() == 'completed'" color="green" class="ml-2">{{icons.mdiEyeCheck}}</v-icon>
                            </template>
                            <template v-slot:item.fwdDICOM="{ item }">
                              <v-icon v-if="!((userInfo.ROLE_ID == 4 || item.User_Email != userInfo.User_Email) && userInfo.ROLE_ID != 1)" color="green" class="ml-2">{{icons.mdiShare}}</v-icon>
                              <v-icon v-else color="red" class="ml-2">{{icons.mdiShareOff}}</v-icon>
                            </template>
                            </v-data-table>
                          </template>
                        </v-flex>
                        <v-flex sm12>
                          <div class="text-xs-center pt-2">
                            <v-pagination v-model="pagination.page" :length="pagination.pageCount"></v-pagination>
                          </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-app>
        </div>
        <ScanlistDialog
            :setVisibleDownloadDiglog="setVisibleDownloadDiglog"
            :setVisibleForwardDialog="setVisibleForwardDialog"
            :setVisibleUploadDialog="setVisibleUploadDialog"
            :downloadDialogVisible="downloadDialogVisible"
            :patient_Info="patient_Info"
        ></ScanlistDialog>
        <ForwardPatient
            :setVisibleForwardDialog="setVisibleForwardDialog"
            :forwardDialogVisible="forwardDialogVisible"
            :patient_List="patient_List"
        ></ForwardPatient>
        <UploadPlan
            :setVisibleUploadDialog="setVisibleUploadDialog"
            :uploadDialogVisible="uploadDialogVisible"
            :patient_Info="patient_Info"
        ></UploadPlan>
        <vsnackbar :snackbar="snackbar" />
    </section>
</template>

<script>
import { getPatientList, postTokenCheck, getCodeName, getDownloadDICOMURL } from '@/api/api'
import ScanlistDialog from '@/components/Patients/ScanlistDialog.vue'
import ForwardPatient from '@/components/ForwardPatient/ForwardPatient.vue'
import UploadPlan from '@/components/UploadPlan/UploadPlan.vue'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import store from '@/store'
import {
  mdiMagnify,
  mdiCalendar,
  mdiAlert,
  mdiEyeCheck,
  mdiShare,
  mdiShareOff
} from '@mdi/js'
import vsnackbar from '@/components/Widgets/v-snackbar'
export default {
  props: ['imaging_name'],
  data () {
    return {
      moment: moment,
      icons: { mdiMagnify, mdiCalendar, mdiAlert, mdiEyeCheck, mdiShare, mdiShareOff },
      search: '',
      patients: [],
      table_loading: false,
      headers: [
        { text: 'HN (Patient ID)', value: 'Patient_ID_Clinic', sortable: true },
        { text: "Patient's Name", value: 'Patient_NameEng', sortable: true },
        { text: 'Scan Time', value: 'Ctdata_Exam_Date', sortable: true },
        { text: 'Upload Time', value: 'UploadTime', sortable: true },
        { text: 'Scan mode', value: 'Ctmode_Description', sortable: false },
        { text: 'Exam Description', value: 'Patient_Description', sortable: true },
        { text: 'Viewer', value: 'reslice', sortable: false },
        { text: 'Forward', value: 'fwdDICOM', sortable: false }
      ],
      downloadDialogVisible: false,
      forwardDialogVisible: false,
      uploadDialogVisible: false,
      patient_Info: {},
      patient_List: [],
      pagination: {
        sortBy: 'UploadTime',
        page: 1,
        pageCount: 0,
        itemsPerPage: 10
      },
      period: {
        selectType: '',
        startDate: '',
        endDate: '',
        startTime: '00:00',
        endTime: '23:59'
      },
      selected: [],
      snackbar: {
        show: false,
        message: '',
        colorValue: 'success',
        timeout: 5000
      }
    }
  },
  computed: {
    pages () {
      if (
        this.pagination.rowsPerPage == null ||
        this.patients.length == 0
      ) { return 0 }

      return Math.ceil(this.patients.length / this.pagination.rowsPerPage)
    },
    socketScan () {
      return this.$store.state.socketScan
    },
    machine () {
      return this.$store.state.baseURL.machine
    },
    userInfo () {
      return JSON.parse(localStorage['RadiiView-DentiiCloud']).user
    },
    statusFWDList () {
      var status = false
      this.selected.forEach(element => {
        status = status || ((this.userInfo.ROLE_ID == 4 || element.User_Email != this.userInfo.User_Email) && this.userInfo.ROLE_ID != 1)
      })
      return status
    }
  },
  components: { ScanlistDialog, ForwardPatient, UploadPlan, vsnackbar },
  methods: {
    setVisibleDownloadDiglog: function (val) {
      this.downloadDialogVisible = val
    },
    setVisibleForwardDialog: function (val) {
      this.forwardDialogVisible = val
    },
    setVisibleUploadDialog: function (val) {
      this.uploadDialogVisible = val
    },
    handleSelectScanList (item) {
      this.downloadDialogVisible = true
      this.patient_Info = {
        doctor_ID_Clinic: item.Doctor_ID_Clinic,
        patient_ID_Clinic: item.Patient_ID_Clinic,
        hn: item.Patient_HNNumber,
        patient_name: item.Patient_NameEng,
        description: item.Patient_Description,
        doctor_name: item.Doctor_Name,
        Ctdata_SerieUID: item.Ctdata_SerieUID,
        Reslice_status: item.Reslice_status,
        scan_date: item.UploadTime,
        Ctmode_Description: item.Ctmode_Description,
        User_Email: item.User_Email
      }
      this.patient_List = [this.patient_Info]
      store.commit('SET_PATIENT_SELECTED', this.patient_Info)
    },
    formatAge (age) {
      if (isNaN(age)) return '-'
      else return age
    },
    formatGender (gender) {
      if (gender == 'M') return 'Male'
      else if (gender == 'F') return 'Female'
      else if (gender == 'O') return 'Other'
      else return '-'
    },
    getPatients () {
      var _this = this
      var patientParams = {
        upload_date_start: this.period.startDate + ' 00:00',
        upload_date_stop: this.period.endDate + ' 23:59'
      }
      // this.listLoading = true
      this.table_loading = true
      // var userInfo = JSON.parse(localStorage['RadiiView-DentiiCloud']).user
      postTokenCheck({ email: this.userInfo.User_Email })
        .then(res => {
          const token = res.data.token
          const tokenJsonObj = jwt_decode(token)
          var radiiViewInfo = {
            user: tokenJsonObj,
            authentication: token
          }
          localStorage['RadiiView-DentiiCloud'] = JSON.stringify(radiiViewInfo)
          getPatientList(patientParams)
            .then(res => {
              _this.patients = res.data
              _this.table_loading = false
            })
            // eslint-disable-next-line handle-callback-err
            .catch(function (err) {
              _this.table_loading = false
              // console.log(err)
            })
        })
        // eslint-disable-next-line handle-callback-err
        .catch(function (err) {
          // _this.table_loading = false
          // console.log(err)
        })
    },
    setPeriodToday () {
      this.period.startDate = ''
      this.period.endDate = ''
      this.period.startDate = moment().format('YYYY-MM-DD')
      this.period.endDate = moment().format('YYYY-MM-DD')
      this.refreshTable()
    },
    setPeriodWeek () {
      this.period.startDate = ''
      this.period.endDate = ''
      this.period.endDate = moment().format('YYYY-MM-DD')
      this.period.startDate = moment().subtract(6, 'days').format('YYYY-MM-DD')
      this.refreshTable()
    },
    setPeriodChoose () {
      this.refreshTable()
    },
    refreshTable () {
      this.getPatients()
    },
    async selectedDownload () {
      this.patient_List = this.selected
      this.selected.forEach(async element => {
        await this.openDownload(element)
      })
    },
    async openDownload (item) {
      const _self = this
      var date = item.UploadTime.split(' ')[0]
      const scan_date = new Date(date).toISOString()
      const params = {
        hospital_id: getCodeName(),
        scan_date: scan_date,
        serie_uid: item.Ctdata_SerieUID
      }
      await getDownloadDICOMURL(params).then((res) => {
        if (res.status == 200 && res.data.status == 'OK') {
          window.open(res.data.url)
          return true
        } else {
          _self.snackbar.show = true
          _self.snackbar.message = 'Download DICOM failed !!'
          _self.snackbar.colorValue = 'red'
          return false
        }
      }).catch(function () {
        _self.snackbar.show = true
        _self.snackbar.message = 'Download DICOM failed !!'
        _self.snackbar.colorValue = 'red'
        return false
      })
      return false
    },
    async selectedFWDDICOM () {
      this.patient_List = this.selected
      this.setVisibleDownloadDiglog(false)
      this.setVisibleForwardDialog(true)
    }
  },
  mounted () {
    if (this.machine.toLowerCase() == 'bodiiray') {
      var filtered = this.headers.filter(function (el) { return el.text != 'Scan Time' })
      this.headers = filtered
    }
    // DEFAULT TODAY TOGGLE
    this.period.startDate = new Date('2019-01-01').toISOString().slice(0, 10)
    this.period.endDate = moment().format('YYYY-MM-DD')
    this.refreshTable()
  },
  watch: {
    socketScan (val) {
      const _self = this
      for (let i = 0; i < _self.patients.length; i++) {
        if (_self.patients[i].Ctdata_SerieUID == val.serieUID) {
          _self.patients[i].Reslice_status = val.status
          break
        }
      }
    }
  }
}
</script>
<style>
  table.v-table tbody td:first-child, table.v-table tbody td:not(:first-child), table.v-table tbody th:first-child, table.v-table tbody th:not(:first-child), table.v-table thead td:first-child, table.v-table thead td:not(:first-child), table.v-table thead th:first-child, table.v-table thead th:not(:first-child){
    padding: 0 8px;
  }
</style>
