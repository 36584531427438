<template>
    <v-card flat>
        <v-list class="scroll-y" >
          <center v-if="file_lists.length == 0">
            No uploaded files.
          </center>

          <v-list-item v-else @click="selectedFile(file_lists[0])">
            <v-list-item-content :id="'selector-name-' + file_lists[0].fileName">
              <v-list-item-title v-text="file_lists[0].fileName"></v-list-item-title>
            </v-list-item-content>
            <!-- <v-list-item-avatar>
              <v-icon v-if="file_lists[0].uploaderInfo.ROLE_TYPE == 4 || file_lists[0].uploaderInfo.ROLE_TYPE == 5" color="success">{{icons.mdiAccountMultiple}}</v-icon>
            </v-list-item-avatar> -->
          </v-list-item>

        </v-list>
    </v-card>
</template>

<script>
import { mdiAccountMultiple } from '@mdi/js'
import { getDownloadPlanURL, handleExpireSession } from '@/api/api'
export default {
  props: ['file_lists', 'patient_Info'],
  data () {
    return {
      icons: { mdiAccountMultiple }
    }
  },
  methods: {
    selectedFile (val) {
      var _this = this
      const param = {
        Ctdata_SerieUID: _this.patient_Info.Ctdata_SerieUID,
        fileName: val.fileName
      }
      // console.log("Val : " + param)
      getDownloadPlanURL(param).then((res) => {
        // eslint-disable-next-line no-mixed-operators
        if (res.status == 200 || res.status == 202) {
          window.open(res.data.url, '_self')
        }
      }).catch(function (err) {
        handleExpireSession(err, _this)
      })
    }
  }
}
</script>

<style>
</style>
