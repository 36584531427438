<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
  /* tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
  } */
</style>
